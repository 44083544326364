import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataAPI } from "../../service/UserService";
import {
  getAnnouncementAPI,
  getCurrentBetsAPI,
} from "../../service/AuthService";
import { Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import moment from "moment";

function Header() {
  const location = useLocation();
  const active = location.pathname.split("/")[1];
  const pathname = location.pathname;
  const [exposureTableModal, setExposureTableModal] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const [announcement, setAnnouncement] = React.useState({});
  const myMarketQuery = useQuery("myMarket", () =>
    getCurrentBetsAPI({
      betStatus: "MATCHED",
      betType: "ALL",
      pageNumber: 0,
      pageSize: 100,
      search: "",
      sports: true,
    })
  );

  const getUserData = async () => {
    var { response, code } = await getUserDataAPI(userData.id);
    if (code === 200) {
      dispatch({
        type: "accountData/setUserProfile",
        payload: response,
      });
    } else {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
    }
  };

  const getAnnouncement = async () => {
    const { response } = await getAnnouncementAPI();
    setAnnouncement(response);
  };

  const fetchProfile = () => {
    const interval = setInterval(getUserData, 3000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    getUserData();
    fetchProfile();
    getAnnouncement();
  }, []);

  if (!userData.token) {
    return (window.location.href = "/");
  }

  return (
    <>
      <Modal
        style={{
          backgroundColor: "transparent",
        }}
        onHide={() => setExposureTableModal(false)}
        show={exposureTableModal}
      >
        <Modal.Header
          style={{
            backgroundColor: "black",
          }}
          closeButton
        >
          <h4>My Market</h4>
        </Modal.Header>
        <Modal.Body
          style={{
            overflowX: "auto",
            textAlign: "center",
          }}
        >
          <div style={{ color: "black" }}>UNSETTLED MATCH BETS</div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Team</th>

                <th>Rate</th>
                <th>Mode</th>
                <th>Amount</th>

                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {myMarketQuery?.data?.response?.unsettledBets?.content
                ?.filter((i) => i.marketName == "BOOKMAKER_ODDS_1")
                ?.map((item) => (
                  <tr>
                    <td>{item?.runner_name}</td>
                    <td>{item?.lay_rate ? item?.lay_rate : item?.back_rate}</td>
                    <td>{item?.lay_rate ? "KHAI" : "LAGAI"}</td>
                    <td>{item?.amount}</td>

                    <td>
                      {moment(item?.created_date + "Z").format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div style={{ color: "black" }}>UNSETTLED SESSION BETS</div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Session</th>

                <th>Rate</th>
                <th>Amount</th>
                <th>Run</th>

                <th>Mode</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {myMarketQuery?.data?.response?.unsettledBets?.content
                ?.filter((i) => i.marketName != "BOOKMAKER_ODDS_1")
                .map((item) => (
                  <tr>
                    <td>{item?.marketName}</td>
                    <td>{item?.lay_rate ? item?.lay_rate : item?.back_rate}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.lay_run ? item?.lay_run : item?.back_run}</td>
                    <td>{item?.lay_rate ? "NO" : "YES"}</td>

                    <td>
                      {moment(item?.created_date + "Z").format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <nav className="bg-theme-dark p-0 navbar navbar-expand-navbar navbar-dark">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <React.Fragment key="right" />
            <Link to="/dashboard" className="logo text-logo">
              2EXCH
            </Link>
          </div>
          <div className="annoucement">
            <marquee>{announcement?.announcement}</marquee>
          </div>

          <div
            onClick={() => {
              myMarketQuery.refetch();
              setExposureTableModal(true);
            }}
            className="exposure"
            style={{ marginTop: "60px" }}
          >
            <center className="flex-count">
              <p>USED COINS</p>
              <p>
                <span className="">{userData.liability}/-</span>
              </p>
            </center>
          </div>
          <div className="profile-details text-center">
            <p className="m-0">{userData?.username}</p>
            <p className="m-0">
              {/* <img
                width="16px"
                src="/assets/images/wallet.png"
                alt="Wallet"
                style={{ marginRight: "3px" }}
              /> */}
              chips: {userData?.balance + userData?.liability}
              {/* <button
                className="btn btn-transparent p-0 m-0"
                onClick={() => {
                  getUserData();
                }}
              >
                <img src="/assets/images/refresh.png" width="20" alt="" />
              </button> */}
            </p>
          </div>
        </div>
      </nav>

      <div className="list-tabs-top">
        <Link to="/dashboard">
          <i className="far fa-home fa-fw" /> Dashboard
        </Link>

        {/* <Link to='/upcoming'>
          <i className='fas fa-gamepad fa-fw'></i> Upcoming Games</Link> */}

        <ul class="menu">
          <li>
            <a href="#">
              <i class="fas fa-sitemap" />
              Report
            </a>
            <ul class="submenu">
              <li>
                <a href="/statement">
                  <i className="far fa-book-reader" />
                  Statement
                </a>
              </li>
              <li>
                <a href="/ledger">
                  <i className="far fa-book-reader" />
                  Ledger
                </a>
              </li>
              <li>
                <a href="/complete_games">
                  <i className="fas fa-gamepad fa-fw" /> Complete Games
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <ul class="menu">
          <li>
            <a href="#">
              <i class="fas fa-user-circle" />
              Profile
            </a>
            <ul class="submenu">
              <li>
                <Link to="/profile">
                  <i className="far fa-user-circle" />
                  Profile
                </Link>
              </li>
              <li>
                <Link to="/change_password">
                  <i className="far fa-lock-open-alt fa-xl" />
                  Change Password
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <Link to="/rules">
          <i className="far fa-info-circle" />
          Rules
        </Link>

        <Link
          onClick={() => {
            dispatch({ type: "accountData/logOut" });
            localStorage.clear();
            window.location.href = "/";
          }}
        >
          <i className="far fa-sign-out-alt fa-fw" />
          LOGOUT
        </Link>
      </div>
    </>
  );
}

export default Header;
