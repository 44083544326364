import React from "react";
import "./matka.scoped.css";
import Slider from "react-slick";
import moment from "moment";
export default function MatkaDashboard() {
  return (
    <div className="dashboard">
      <div className="introwatch">
        <p>Coming Soon ....</p>
        <p>🙏🏼 2EXCH Matka 🙏🏼</p>
        <div className="watch">
          <p>
            <time dateTime={1719495897220}>
              {moment().format("DD-MM-YYYY")}
            </time>
          </p>
          <time dateTime={1719495928225}>{moment().format("hh:mm A")}</time>
        </div>
      </div>
      <div
        style={{
          overflowY: "hidden",
        }}
        className="carousel-root result-slider"
      >
        <Slider>
          <li className="slide">
            <div className="silders">
              <p className="header">Disawar</p>
              <p className="content">Result - 40</p>
              <p className="content">27-06-2024 06:00 AM</p>
            </div>
          </li>

          <li className="slide">
            <div className="silders">
              <p className="header">Gali</p>
              <p className="content">Result - 72</p>
              <p className="content">26-06-2024 11:59 PM</p>
            </div>
          </li>

          <li className="slide">
            <div className="silders">
              <p className="header">Faridabaad</p>
              <p className="content">Result - 88</p>
              <p className="content">26-06-2024 06:00 PM</p>
            </div>
          </li>
        </Slider>
      </div>
      <div
        style={{
          position: "static",
        }}
        className="row"
      >
        <div
          onClick={() => {
            window.location.href = "/matka/FARIDABAAD";
          }}
          className="col-md-3 col-sm-12"
        >
          <div className="matka-round ">
            <div className="details">
              <p className="heading">Faridabaad</p>
              <p className="subline">
                07:00 AM To 05:41 PM Result at 06:00 PM
              </p>
            </div>
            <div>
              <p className="number">--</p>
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            window.location.href = "/matka/GALI";
          }}
          className="col-md-3 col-sm-12"
        >
          <div className="matka-round ">
            <div className="details">
              <p className="heading">Gali</p>
              <p className="subline">
                07:00 AM To 11:45 PM Result at 11:59 PM
              </p>
            </div>
            <div>
              <p className="number">--</p>
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            window.location.href = "/matka/DISAWAR";
          }}
          className="col-md-3 col-sm-12"
        >
          <div className="matka-round ">
            <div className="details">
              <p className="heading">Disawar</p>
              <p className="subline">
                09:00 AM To 03:20 AM Result at 06:00 AM
              </p>
            </div>
            <div>
              <p className="number">--</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
